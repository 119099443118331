<template>
  <DashboardLayout>
    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
      <div class="row g-3 justify-content-evenly">
        <div class="col-md-4 card p-0 border-dark rounded-0">
          <h5 class="card-header bg-dark text-light p-3 rounded-0">
            İşlem Yapılacak Hesap
          </h5>
          <div class="card-body">
            <ExchangesSelect
              labelName="İşlem Yapılacak Hesap"
              inputName="exchange"
              :errors="errors"
              :getData="getById"
            ></ExchangesSelect>
            <div class="d-flex mt-3 justify-content-evenly">
              <div>USD: {{ wallet.USD }}$</div>
              <div>BTC: {{ wallet.BTC }}</div>
            </div>
            <RatioSelect
              class="mt-3"
              inputName="ratio"
              :setRatio="setRatio"
              :errors="errors"
            />
          </div>
          <div class="card-footer d-flex align-items-center p-3">
            <label for="strategy">Strategy</label>
            <div class="btn-group flex-wrap w-100 ms-2">
              <Field
                value="long"
                type="radio"
                class="btn-check"
                name="strategy"
                id="strategy1"
                autocomplete="off"
                :class="{ 'is-invalid': errors.strategy }"
              />
              <label class="btn btn-outline-dark btn-sm" for="strategy1"
                >LONG</label
              >

              <Field
                value="short"
                type="radio"
                class="btn-check"
                name="strategy"
                id="strategy2"
                autocomplete="off"
                :class="{ 'is-invalid': errors.strategy }"
              />
              <label
                class="btn btn-outline-dark btn-sm rounded-0"
                for="strategy2"
                >SHORT</label
              >

              <ErrorMessage class="invalid-feedback" name="strategy" />
            </div>
          </div>
        </div>
        <div class="col-md-7 card p-0 border-dark rounded-0">
          <h5 class="card-header bg-dark text-light p-3 rounded-0">
            İşlem Formu
          </h5>
          <div class="card-body row g-3">
            <input-group
              class="col-md-3"
              _type="number"
              inputName="start_point"
              labelName="Başlangıç Noktası"
              :errors="errors.start_point"
            />
            <div class="col-md-4">
              <div class="row g-1">
                <input-group
                  class="col-lg-7"
                  _type="number"
                  inputName="finish_point"
                  labelName="Bitiş Noktası"
                  :errors="errors.finish_point"
                />
                <input-group
                  class="col-lg-5"
                  inputName="finish_point_type"
                  labelName="Tür"
                  :errors="errors.finish_point_type"
                  :select="true"
                  _as="select"
                  :options="options"
                />
              </div>
            </div>
            <div class="col-md-5">
              <div class="row g-1">
                <input-group
                  class="col-lg-7"
                  _type="number"
                  inputName="trigger_point"
                  labelName="Tetiklenme Noktası"
                  :errors="errors.trigger_point"
                />
                <input-group
                  class="col-lg-5"
                  inputName="trigger_point_type"
                  labelName="Tür"
                  :errors="errors.trigger_point_type"
                  :select="true"
                  _as="select"
                  :options="options"
                />
              </div>
            </div>
            <input-group
              class="col-md-4"
              _type="datetime-local"
              inputName="end_date"
              labelName="Bitiş Tarihi"
              :errors="errors.trigger_point"
              :_disabled="limitless"
            />
            <input-group
              class="col-md-4"
              inputName="order_type"
              labelName="İşlem Türü"
              :errors="errors.order_type"
              :select="true"
              _as="select"
              :options="[
                { name: 'Limit', value: 'EXCHANGE LIMIT' },
                { name: 'Scaled', value: 'SCALED' },
              ]"
              :_change="visibleScaledOrder"
            />
            <div class="col-md-2" v-if="scaled_order">
              <label for="order_count">Sipariş Sayısı</label>
              <Field
                as="input"
                type="number"
                class="form-control"
                :class="{ 'is-invalid': errors.order_count }"
                min="2"
                name="order_count"
                id="order_count"
              />
              <ErrorMessage name="order_count" class="invalid-feedback" />
            </div>
          </div>
          <div
            class="
              card-footer
              d-flex
              justify-content-between
              align-items-center
              p-3
            "
          >
            <div class="form-check form-switch">
              <label class="me-1" for="limitless">Süresiz</label>
              <Field
                type="checkbox"
                name="limitless"
                id="limitless"
                class="form-check-input"
                value="limitless"
                @input="checkedLimitless"
              />
            </div>
            <div class="btn-group flex-wrap w-50 ms-2">
              <Field
                value="EXCHANGE"
                type="radio"
                class="btn-check"
                name="order_context"
                id="order_context1"
                autocomplete="off"
                :class="{ 'is-invalid': errors.order_context }"
              />
              <label class="btn btn-outline-dark btn-sm" for="order_context1"
                >EXCHANGE</label
              >

              <Field
                value="MARGIN"
                type="radio"
                class="btn-check"
                name="order_context"
                id="order_context2"
                autocomplete="off"
                :class="{ 'is-invalid': errors.order_context }"
              />
              <label
                class="btn btn-outline-dark btn-sm rounded-0"
                for="order_context2"
                >MARGIN</label
              >

              <ErrorMessage class="invalid-feedback" name="order_context" />
            </div>
            <button type="submit" class="btn btn-primary btn-sm rounded-0 w-25">
              İşlemi Başlat
            </button>
          </div>
        </div>
      </div>
    </Form>
    <div class="row mt-5 justify-content-center">
      <div class="col-md-11 card p-0 border-dark rounded-0">
        <h5 class="card-header bg-dark text-light p-3 rounded-0">İşlemlerim</h5>
        <div class="card-body">
          <table class="table" v-if="activePriceSignals.length !== 0">
            <thead>
              <tr>
                <th scope="col">Pair</th>
                <th scope="col">Type</th>
                <th scope="col">Tetiklenme</th>
                <th scope="col">Başlangıç Noktası</th>
                <th scope="col">Bitiş</th>
                <th scope="col">Başlangıç</th>
                <th scope="col">Durum</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="priceSignal in activePriceSignals"
                :key="priceSignal.id"
              >
                <th scope="row">{{ priceSignal.pair }}</th>
                <td>{{ priceSignal.order_type }}</td>
                <td>{{ priceSignal.trigger_point }}</td>
                <td>{{ priceSignal.start_point }}</td>
                <td>{{ priceSignal.finish_point }}</td>
                <td>{{ convertTime(priceSignal.create_date) }}</td>
                <td>{{ priceSignal.status }}</td>
                <td>
                  <div class="btn-group" role="group">
                    <button type="button" class="btn btn-info">
                      <i class="bi bi-info"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      @click="_deletePriceSignal(priceSignal.id)"
                    >
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="text-center">Aktif sinyal bulunmuyor!</div>
        </div>
      </div>
    </div>
    <div class="row mt-5 justify-content-center">
      <div class="col-md-11 card p-0 border-dark rounded-0">
        <h5 class="card-header bg-dark text-light p-3 rounded-0">
          İşlem Geçmişim
        </h5>
        <div class="card-body">
          <table class="table" v-if="deactivePriceSignals.length !== 0">
            <thead>
              <tr>
                <th scope="col">Pair</th>
                <th scope="col">Type</th>
                <th scope="col">Tetiklenme</th>
                <th scope="col">Başlangıç Noktası</th>
                <th scope="col">Bitiş</th>
                <th scope="col">Başlangıç</th>
                <th scope="col">Durum</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="priceSignal in deactivePriceSignals"
                :key="priceSignal.id"
              >
                <th scope="row">{{ priceSignal.pair }}</th>
                <td>{{ priceSignal.order_type }}</td>
                <td>{{ priceSignal.trigger_point }}</td>
                <td>{{ priceSignal.start_point }}</td>
                <td>{{ priceSignal.finish_point }}</td>
                <td>{{ convertTime(priceSignal.create_date) }}</td>
                <td>{{ priceSignal.status }}</td>
                <td>
                  <div class="btn-group" role="group">
                    <button type="button" class="btn btn-info">
                      <i class="bi bi-info"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="text-center">
            Daha önceden oluşturulmuş bir sinyal bulunmuyor!
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/layouts/Dashboard.layout.vue";
import ExchangesSelect from "@/components/ExchangesSelect.vue";
import RatioSelect from "@/components/RatioSelect.vue";

import { mapActions, mapState } from "vuex";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

import moment from "moment";
import InputGroup from "../../components/Dashboard/Form/InputGroup.vue";

export default {
  components: {
    DashboardLayout,
    ExchangesSelect,
    Form,
    Field,
    RatioSelect,
    ErrorMessage,
    InputGroup,
  },
  data() {
    return {
      options: [
        { value: "percent", name: "%" },
        { value: "price", name: "Net Miktar" },
        { value: "amount", name: "Miktar" },
      ],
      exchange: "",
      api_key: "",
      secret_key: "",
      ratio: "",
      order_type: "",
      scaled_order: false,
      limitless: false,
    };
  },
  created() {
    this.getPriceSignals({ url: "/price-signal/" });
  },
  methods: {
    ...mapActions("exchange", [
      "getExchange",
      "connectExchange",
      "createPriceSignal",
      "getPriceSignals",
      "deletePriceSignal",
    ]),
    convertTime(date) {
      return moment(date).format("LLL");
    },
    getById(id) {
      const exchange = this.exchanges.filter((exchange) => {
        return exchange.id == id;
      });
      this.exchange = exchange[0].exchange;
      this.api_key = exchange[0].api_key;
      this.secret_key = exchange[0].secret_key;
      this.connectExchange({
        data: {
          api_key: this.api_key,
          secret_key: this.secret_key,
          exchange: this.exchange,
        },
      });
    },
    setRatio(e) {
      this.ratio = e.target.value;
    },
    visibleScaledOrder(order_type) {
      order_type === "SCALED"
        ? (this.scaled_order = true)
        : (this.scaled_order = false);
    },
    checkedLimitless() {
      this.limitless = !this.limitless;
    },
    onSubmit(values) {
      values["api_key"] = this.api_key;
      values["secret_key"] = this.secret_key;
      values["exchange_name"] = this.exchange;
      values["limitless"] === "limitless"
        ? (values["limitless"] = true)
        : (values["limitless"] = false);

      values["end_date"] !== ""
        ? (values["end_date"] = moment(values["end_date"]).format(
            "YYYY-MM-D H:mm:ss"
          ))
        : delete values["end_date"];
      values["start_point"] = parseInt(values["start_point"]);

      if (values["strategy"] === "long") {
        if (values["finish_point_type"] === "percent") {
          values["finish_point"] =
            values["start_point"] +
            values["start_point"] * (parseInt(values["finish_point"]) / 100);
        } else if (values["finish_point_type"] === "amount") {
          values["finish_point"] =
            values["start_point"] + parseInt(values["finish_point"]);
        } else {
          values["finish_point"] = parseInt(values["finish_point"]);
        }

        if (values["trigger_point_type"] === "percent") {
          values["trigger_point"] =
            values["start_point"] -
            values["start_point"] * (parseInt(values["trigger_point"]) / 100);
        } else if (values["trigger_point_type"] === "amount") {
          values["trigger_point"] =
            values["start_point"] - parseInt(values["trigger_point"]);
        } else {
          values["trigger_point"] = parseInt(values["trigger_point"]);
        }

        if (
          !(
            values["finish_point"] > values["start_point"] &&
            values["start_point"] > values["trigger_point"]
          )
        ) {
          console.error("Long stratejisine uygun değerler girilmelidir!");
        }
      } else if (values["strategy"] === "short") {
        if (values["finish_point_type"] === "percent") {
          values["finish_point"] =
            values["start_point"] -
            values["start_point"] * (parseInt(values["finish_point"]) / 100);
        } else if (values["finish_point_type"] === "amount") {
          values["finish_point"] =
            values["start_point"] - parseInt(values["finish_point"]);
        } else {
          values["finish_point"] = parseInt(values["finish_point"]);
        }

        if (values["trigger_point_type"] === "percent") {
          values["trigger_point"] =
            values["start_point"] +
            values["start_point"] * (parseInt(values["trigger_point"]) / 100);
        } else if (values["trigger_point_type"] === "amount") {
          values["trigger_point"] =
            values["start_point"] + parseInt(values["trigger_point"]);
        } else {
          values["trigger_point"] = parseInt(values["trigger_point"]);
        }
        if (
          !(
            values["finish_point"] < values["start_point"] &&
            values["start_point"] < values["trigger_point"]
          )
        ) {
          console.error("Short stratejisine uygun değerler girilmelidir!");
        }
      }

      values["ratio"] = parseInt(values["ratio"]);
      !isNaN(values["order_count"])
        ? (values["order_count"] = parseInt(values["order_count"]))
        : (values["order_count"] = 1);
      delete values["finish_point_type"];
      delete values["trigger_point_type"];

      console.log(values);
      values["pair"] = "BTCUSD";
      this.createPriceSignal({ data: values });
    },
    _deletePriceSignal(id) {
      const signal = this.activePriceSignals.filter((_signal) => {
        return _signal.id == id;
      });
      const exchange = this.exchanges.filter((exchange) => {
        return exchange.id == signal[0].exchange;
      });
      this.deletePriceSignal({ pk: id, exchange_name: exchange[0].exchange });
    },
  },
  computed: {
    ...mapState("exchange", [
      "exchanges",
      "wallet",
      "activePriceSignals",
      "deactivePriceSignals",
    ]),
  },
  setup() {
    const schema = Yup.object().shape({
      exchange: Yup.string().required("Zorunlu alan!"),
      ratio: Yup.number().required("Bir oran seçmelisiniz!"),
      strategy: Yup.string().required("Zorunlu alan!"),
      order_type: Yup.string().required("Zorunlu alan!"),
      limitless: Yup.string(),
      order_context: Yup.string().required("Zorunlu alan!"),
      end_date: Yup.string().nullable(),
      start_point: Yup.number()
        .typeError("Zorunlu alan!")
        .required("Zorunlu alan!")
        .min(0, "0'dan küçük değer giremezsiniz!"),
      order_count: Yup.number()
        .typeError("Zorunlu alan!")
        .required("Zorunlu alan!")
        .min(2, "En az 2 tane sipariş oluşturabilirsiniz!")
        .default(2),
      finish_point: Yup.number()
        .typeError("Zorunlu alan!")
        .required("Zorunlu alan!")
        .min(0, "0'dan küçük değer giremezsiniz!"),
      finish_point_type: Yup.string().required("Zorunlu alan!"),
      trigger_point: Yup.number()
        .typeError("Zorunlu alan!")
        .required("Zorunlu alan!")
        .min(0, "0'dan küçük değer giremezsiniz!"),
      trigger_point_type: Yup.string().required("Zorunlu alan!"),
    });

    return {
      schema,
    };
  },
};
</script>
